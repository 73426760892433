<template>
  <div>
    <a-descriptions
      :column="3"
      class="simiot-descriptions simiot-descriptions-max-6"
    >
      <a-descriptions-item label="月份">
        {{ data.month }}
      </a-descriptions-item>

      <a-descriptions-item label="状态">
        {{ data.status }}
      </a-descriptions-item>

      <a-descriptions-item label="结算金额(元)">
        {{ data.total_fee | formatCurrency }}
      </a-descriptions-item>
    </a-descriptions>

    <a-divider />

    <a-table
      style="overflow: auto"
      size="middle"
      :columns="tableColumns"
      :data-source="tableData"
      :pagination="false"
      :expand-icon="expandIcon"
      row-key="index"
    >
      <div slot-scope="record" slot="expandedRowRender">
        <component
          :is="currentComponent(record.unit_type_slug)"
          :bill-id="agentMonthlyBillId"
        />
      </div>
    </a-table>
  </div>
</template>

<script>
import { findAgentMonthlyBill } from '@/api/agent_monthly_bill'
import { formatBigNumber, formatCurrency } from '@/utils/filter'

export default {
  name: 'BalanceStatisticAgentMonthlyBillInfo',
  components: {
    PurchaseBillList: () => import('@/views/agent_monthly_bills/purchase/index'),
    ActiveBillList: () => import('@/views/agent_monthly_bills/active/index'),
    CardUseBillList: () => import('@/views/agent_monthly_bills/card_use/index'),
    RenewBillList: () => import('@/views/agent_monthly_bills/renew/index'),
    SubscribeBillList: () => import('@/views/agent_monthly_bills/subscribe/index'),
    StopBillList: () => import('@/views/agent_monthly_bills/stop/index'),
    SubscribeTrafficPoolBillList: () => import('@/views/agent_monthly_bills/subscribe_traffic_pool/index')
  },
  data() {
    return {
      data: {},
      tableData: []
    }
  },
  computed: {
    tableColumns() {
      return [
        {
          title: '账单类型',
          width: 350,
          dataIndex: 'unit_type'
        },
        {
          title: '个数',
          width: 250,
          customRender: formatBigNumber,
          dataIndex: 'bill_count'
        },
        {
          title: '结算金额(元)',
          customRender: formatCurrency,
          dataIndex: 'total_fee'
        }
      ]
    },

    agentMonthlyBillId() {
      return parseInt(this.$route.params.agent_monthly_bill_id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    expandIcon({ expanded, expandable, record, onExpand }) {
      if (!expandable || record.bill_count === 0) return null

      return (
        <a onClick={e => onExpand(record, e)}>
          {expanded ? <a-icon type='minus-square' /> : <a-icon type='plus-square' />}
        </a>
      )
    },

    currentComponent(unit_type_slug) {
      switch (unit_type_slug) {
        case 'purchase':
          return 'PurchaseBillList'
        case 'active':
          return 'ActiveBillList'
        case 'card_use':
          return 'CardUseBillList'
        case 'renew':
          return 'RenewBillList'
        case 'subscribe':
          return 'SubscribeBillList'
        case 'stop':
          return 'StopBillList'
        case 'subscribe_traffic_pool':
          return 'SubscribeTrafficPoolBillList'
        default:
          if (unit_type_slug !== '') {
            console.log(`请配置 ${unit_type_slug}`)
          }
          return ''
      }
    },

    fetchData() {
      findAgentMonthlyBill(this.agentMonthlyBillId).then((res) => {
        this.data = res.data
        this.tableData = [
          { index: 0, unit_type: '采购账单', unit_type_slug: 'purchase', bill_count: this.data.purchased_count, total_fee: this.data.purchased_fee },
          { index: 1, unit_type: '激活账单', unit_type_slug: 'active', bill_count: this.data.actived_count, total_fee: this.data.actived_fee },
          { index: 2, unit_type: '续期账单', unit_type_slug: 'renew', bill_count: this.data.renewed_count, total_fee: this.data.renewed_fee },
          { index: 3, unit_type: '套餐订购账单', unit_type_slug: 'subscribe', bill_count: this.data.subscribed_count, total_fee: this.data.subscribed_fee },
          { index: 4, unit_type: '停机保号账单', unit_type_slug: 'stop', bill_count: this.data.stopped_count, total_fee: this.data.stopped_fee },
          { index: 5, unit_type: '流量使用详情账单', unit_type_slug: 'card_use', bill_count: this.data.card_used_count, total_fee: this.data.card_used_fee },
          { index: 6, unit_type: '流量池订购账单', unit_type_slug: 'subscribe_traffic_pool', bill_count: this.data.traffic_pool_subscribed_count, total_fee: this.data.traffic_pool_subscribed_fee }
        ]
      })
    }
  }
}
</script>

